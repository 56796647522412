<template>
  <div class="mall-box">
    <ul class="mall-list">
      <!--正常链接跳转-->
      <li v-for="(mall, index) in mallList" :key="index" >
          <div v-if="!mall.isWxLabel" class="mall"  @click="handleHref(mall)">
            <img :src="mall.logoUrl" />
              <div class="mall-content tal">
                <h4>{{ mall.title }}</h4>
                <p class="follow">关注：{{ mall.follow }}</p>
                <p class="newAdd">较昨日新增：{{ mall.newAddCount }}</p>
              </div>
          </div>
          <div  class="mall" v-else>
             <wx-open-launch-weapp
                    id="launch-btn"
                    :username="mall.originalId"
                >
                <script type="text/wxtag-template">
                  <style> img {
                    width: 42px;
                    margin: 12px 10px;
                  }
                  .mall {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 169px;
                    height: 68px;
                    box-shadow: 0 0 15px #f0f0f0;
                    border-radius: 8px;
                    margin-bottom: 12px;
                     padding-top:10px;
                  }
                  h4,p {
                      padding: 0;
                      margin: 0;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 100px;
                    }
                    h4 {
                      color: #222;
                    }
                    p {
                      font-size: 12px;
                      color: #b2b2b2;
                    }</style>
                  <div class="mall">
                    <img src="{{mall.logoUrl}}" />
                    <div class="mall-content tal">
                      <h4>{{ mall.title }}</h4>
                      <p class="follow">关注：{{ mall.follow }}</p>
                      <p class="newAdd">较昨日新增：{{ mall.newAddCount }}</p>
                    </div>
                  </div>
                </script>     
                </wx-open-launch-weapp>
          </div>
      </li>
    </ul>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      top="5vh"
      width="30%"
      :before-close="closeDialog">
      <img style="width:100%" :src="qrcodeImgUrl">
    </el-dialog>
  </div>
</template>
<script>
import { isWeiXin } from '@/utils'
export default {
  
  data() {
    const isWeiXinLabel = isWeiXin()
    return {
      isWeixinEnd: false,
      qrcodeImgUrl:'',
      dialogVisible: false,
      title: "扫码提示",
      mallList: [
        {
          logoUrl: "https://test.h5.interescamp.com/img/company-logo/lb2.png",
          title: "沈阳自贸跨境电商平台",
          follow: 552,
          newAddCount: 10,
          href: "https://m.wmshop.lnfta.com",
          isWxLabel:false,
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: "https://test.h5.interescamp.com/img/company-logo/lb2-1-1.png",
          title: "沈阳自贸区盛大门全球精品直达店",
          follow: 6753,
          newAddCount: 11,
          isWxLabel:isWeiXinLabel,
          originalId:'gh_74a6833da088',// 使用小程序_原始ID 进行页面跳转
          href:'',   // 没有跳转链接放置二维码图片地址
          isHref: false, // 链接直接跳转， 否则使用小程序标签
        },
        {
          logoUrl: "https://test.h5.interescamp.com/img/company-logo/lb3.png",
          title: "云海淘",
          follow: 5982,
          newAddCount: 10,
          isWxLabel:false,
          href: "http://mall.yunhaitao.com/index.php/wap/shop/index?shop_id=1",
          isHref: true, // 链接直接跳转， 否则使用小程序标签
        },{
          logoUrl: "https://test.h5.interescamp.com/img/lb2-1-1.png",
          title: "沈阳自贸区盛大门全球精品直达店",
          follow: 6753,
          newAddCount: 11,
          isWxLabel:isWeiXinLabel,
          originalId:'gh_74a6833da088',// 使用小程序_原始ID 进行页面跳转
          href:'',   // 没有跳转链接放置二维码图片地址
          isHref: false, // 链接直接跳转， 否则使用小程序标签
        },{
          logoUrl: "https://test.h5.interescamp.com/img/lb2-1-1.png",
          title: "沈阳自贸区盛大门全球精品直达店",
          follow: 6753,
          newAddCount: 11,
          isWxLabel:isWeiXinLabel,
          originalId:'gh_74a6833da088',// 使用小程序_原始ID 进行页面跳转
          href:'',   // 没有跳转链接放置二维码图片地址
          isHref: false, // 链接直接跳转， 否则使用小程序标签
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    handleHref(item){
      const malls = ["沈阳自贸区盛大门全球精品直达店","云通全球购","丝路全球购",'万疆优选跨境商城']
      console.log(malls.includes(item.title))
      if(malls.includes(item.title)){
        // 用弹窗显示二维码扫码
        this.dialogVisible = true;
        this.qrcodeImgUrl = item.href;
        this.title = '扫码进入小程序'
      }else if(item.href){
         window.open(item.href)
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    openDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.mall-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .mall {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 169px;
    height: 68px;
    box-shadow: 0 0 15px #f0f0f0;
    border-radius: 8px;
    margin-bottom: 12px;
    img {
      width: 42px;
      margin: 12px 10px;
    }
    .mall-content {
      h4,
      p {
        padding: 0;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
      }
      h4 {
        color: #222;
      }
      p {
        font-size: 12px;
        color: #b2b2b2;
      }
    }
  }
}
</style>
