<template>
    <div class="schoolDetail" v-if="schoolInfo">
         <MallList></MallList>
         <div class="title">{{schoolInfo.orgName}}</div>
            <div class="tac" v-if="schoolInfo.brandCover">
            <el-image
                style="width:100%"
                :src="schoolInfo.brandCover"
                fit="contain"
                ></el-image>
            </div>
        <div class="pd10">
            <div class="subTitle">一、办学特色</div>
            <div class="selfStyle" v-html="schoolInfo.feature"></div>
            <div class="subTitle">二、品牌故事</div>
            <div class="selfStyle"  v-html="schoolInfo.brandStory"></div>
            <div class="subTitle">三、荣誉资质</div>
            <div class="selfStyle"  v-html="schoolInfo.brandHonor"></div>
        </div>
    </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import { getSiteInfo,getSign } from '@/api/site'
import { appId } from '@/config/sys-config'
import MallList from '../mall-list'
export default {
    components:{
        MallList
    },
    props:{
      tcode:{
         type:String,
         require:true
      }
    },
    data() {
        return {
            websiteSetting:null,
            schoolInfo:null
        }
    },
     mounted() {
      this.setWxConfig()
      this.getSiteInfo()
   },
   methods:{
       // 获取官网信息
      getSiteInfo(){
         const tcode = this.$route.query.tcode
         getSiteInfo({tcode}).then(res=>{
            if(res && res.code ===200){
                // 代码转义
                let data = JSON.parse(res.data.content);
                this.websiteSetting = data.websiteSetting
                const pageSetting = data.websiteSetting.pageSetting
                console.log(data,pageSetting,'ddd')
                // 官网信息
                this.componentList = data.componentList
                let schoolInfo = {}
                data.componentList.filter(item =>{
                    if(item.componentId ==='品牌介绍'){
                     schoolInfo = item.schoolInfo 
                    }
                })
                schoolInfo.orgName = pageSetting.websiteName
                schoolInfo.brandCover = pageSetting.brandCover
                // 处理图片展示 
                schoolInfo =  JSON.stringify(schoolInfo).replace(/<img/ig,'<img style=width:100%');
                this.schoolInfo = JSON.parse(schoolInfo)
                this.selfShare()
            }
         })
      },
      // 获取签名信息
      setWxConfig(){
         const url = location.href.split('#')[0]
         getSign({url}).then(res=>{
            if(res && res.code ===200){
                const data = res.data
                // 设置weixin-js-sdk接口配置
                wx.config({
                  debug: false,//是否开启调试功能，这里关闭！
                  appId,//appid
                  timestamp: data.timestamp, //时间戳
                  nonceStr: data.nonceStr, //生成签名的随机字符串
                  signature: data.signature,//签名
                  jsApiList: [
                        "updateTimelineShareData",
                        "updateAppMessageShareData"
                  ]
               });
            }
         })
      },
      // 自定义分享
      selfShare() {
         const shareSetting = this.websiteSetting.shareSetting
         const imgUrl = shareSetting.previewImageUrl
         const desc = shareSetting.description
         const link = location.href.split('#')[0]
         const title = shareSetting.title
         var defaults = {
            title,
            desc,
            type: "link",
            link, //分享页面地址,不能为空，这里可以传递参数！！！！！！！
            imgUrl, //分享是封面图片，不能为空
            success: function () {
               wx.showToast({
                  title:'分享成功！'
               })
            }, //分享成功触发
            cancel: function () {
               wx.showToast({
                  title:'分享失败！'
               })
               } //分享取消触发，需要时可以调用
         }
         wx.ready(function () {
            // 分享到朋友圈
            wx.updateTimelineShareData(defaults);
            // 分享给朋友
            wx.updateAppMessageShareData(defaults);
         });
      },
   }
}
</script>
<style lang="less">
    p {
           word-break: break-all!important;
       }
</style>
<style lang="less" scoped>
    .title {
        text-align: center;
       font-size:36rpx;
       font-weight: 600;
       padding:10px;
       margin-bottom:5px;
       border-bottom: 1px solid #eee;
    }
    .pd10 {
        padding:10px;
    }
    .schoolDetail {
        background-color:#fff;
        .subTitle {
            border-radius: 30px;
            box-shadow: 1px 2px 3px #ccc;
            text-align: left;
            padding: 5px 20px;
            font-weight: 700;
        }
    }
    .selfStyle {
        padding:10px;
    }
</style>